<template>
  <div v-if="projectCookieConsentExists">
    <page-loader size="32" v-if="loading" />
    <div class="d-flex align-items-center" v-else>
      <v-btn
        x-small
        color="primary"
        text
        @click="checkStatus"
        v-if="!active && !refreshed"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <th-badge :color="active ? 'success' : 'danger'" :outlined="!active">
        {{ $translation.t(active ? "Active" : "Not active") }}
      </th-badge>
    </div>
  </div>
</template>

<script>
import PageLoader from "../../PageLoader";
import ThBadge from "../../GeneralComponents/ThBadge";
export default {
  name: "CookieConsentBannerStatus",
  components: { ThBadge, PageLoader },
  data() {
    return {
      loading: true,
      active: false,
      refreshed: false,
    };
  },
  async created() {
    await this.checkStatus();
    this.refreshed = false;
  },
  computed: {
    projectCookieConsentExists() {
      return !!this.$route.params.uuid;
    },
  },
  methods: {
    async checkStatus() {
      if (!this.projectCookieConsentExists) return;
      this.loading = true;
      try {
        this.active = !!(await this.$axios.get(
          `/api/v1/business/cookie-consent/${this.$route.params.uuid}/status`
        ));
        this.refreshed = true;
      } catch (e) {
        this.$log.notify(e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
